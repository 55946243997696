import React, { useState, useEffect } from 'react';

//import image from '../assets/header/sdssd.PNG';
import g298 from '../assets/header/f298.webp';
import bluemobile from '../assets/header/bluesecmb.svg';

const AboutSection = ({ toggleModal }) => {
  const [bg, setBg] = useState(g298);
  const [height, setHeight] = useState('50vh');
  
  const [bgScale, setBgScale] = useState("cover");

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 768;
      setBg(isMobile ? bluemobile : g298);
      setHeight(isMobile ? '65vh' : '');
      setBgScale(isMobile ? "cover" : "cover");
      
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const styles = {
    backgroundImage: `url(${bg})`,
    backgroundSize: bgScale,
    backgroundPosition: "center",
    width: "100%",
    height: height,
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="text-center pb-0  lg:py-10  text-white" style={styles}>
      <div className="container pblsection max-w-5xl px-4 mx-auto pl-3">
        <div className="md:flex justify-start items-start">
          <div className="md:w-1/2 mt-8 mb-9 text-left">
            <h2 className="text-left text-2xl font-bold mb-4 textcolor2">About Dhaara Smart</h2>
            <p
              className="text-left text-sm lg:text-lg mb-6 textcolor font-normal"
             
            >
              The Dhaara Smart is a new-age water meter that combines the power of AI with the latest sensor technologies. The inbuilt telemetry and battery-powered capabilities make it easy to manage your water consumption from anywhere and anytime - even when the power is down!
            </p>
            <button
              className="text-black font-semibold py-2 px-4 rounded "
              style={{
                background: '#FDB740',
                paddingLeft: '2.5rem',
                paddingRight: '2.5rem',
              }}
              onClick={toggleModal}
            >
              Get a call back
            </button>
          </div>
          <div className="md:w-1/2 mt-6 md:mt-0">
            {/* <img
              src={image}
              alt="Dhaara Smart"
              className="sideimage"
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
