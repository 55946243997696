import React from 'react';
import whyuse from '../assets/header/Group309.png';
import dhaara from '../assets/header/exploded.png';
const WhyUseDhaaraSmartMob = ({toggleModal}) => {
  return (
    <div className="text-center py-4 bg-white" >
    <div>

    <h2 className="text-xl lg:text-xl font-semibold headingtext mt-1 mb-5">
        Why use Dhaara Smart?
      </h2>


    <div
     
    >
      

      <div className="max-w-2xl mx-auto grid grid-cols-1 md:grid-cols-1 gap-8 items-center">
 
       <img src={dhaara} alt="Battery" style={{width:'100%'}}/>
       
      </div><br></br>
    
    </div>
    <button className=" text-black font-semibold py-2 px-4 rounded " style={{background: '#FDB740',paddingLeft: '2.5rem',
    paddingRight: '2.5rem',marginBottom:'2.5rem'
}} onClick={toggleModal}>
          Get a call back
        </button>
    </div>
    </div>
  );
};

const Feature = ({ title, description }) => (
  <div className="text-end px-6">
    <h3 className="text-md font-semibold text-blue-900 mb-2">{title}</h3>
    <p className="text-md text-gray-700">{description}</p>
  </div>
);
const Features = ({ title, description }) => (
  <div className="text-left px-6">
    <h3 className="text-md font-semibold text-blue-900 mb-2">{title}</h3>
    <p className="text-md text-gray-700">{description}</p>
  </div>
);

export default WhyUseDhaaraSmartMob;
