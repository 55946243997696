import React from 'react';
import group3 from '../assets/header/Groupf.png';
import group1 from '../assets/header/mm1.png';
import group2 from '../assets/header/mm2.png';
import compare2 from '../assets/header/cmp.png';
const CompareSection = ({toggleModal}) => {
  return (
    <div className="text-center py-10 bg-blue-50">
    <div className="container mx-auto px-4">
      <h2 className="text-3xl font-bold headingtext mb-6" style={{    marginTop: '1rem',
    marginBottom: '2rem'}}>Compare the cost and save money</h2>
      <div className="flex justify-center">
        <div className=" w-full md:w-2/3 lg:w-1/2">
          <div className="grid grid-cols-1 gap-3">
          <img src={compare2} alt="compare section"  className='w-full'/>
          </div>
        </div>
      </div>
      <button className=" text-black font-semibold py-2 px-4 rounded  mb35 " style={{background: '#FDB740',paddingLeft: '2.5rem',
    paddingRight: '2.5rem',marginBottom:'0',
}} onClick={toggleModal}>
          Get a call back
        </button>
    </div>
  </div>
  
  );
}


const CheckIcon = () => <svg width="20" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 11.3688L7.91552 17.0268C8.19052 17.2898 8.62526 17.2849 8.89434 17.0159L23.9101 1.99976" stroke="#00FF00" stroke-width="4" stroke-linecap="round"/>
</svg>
;
const CrossIcon = () => <svg width="18" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.001 19.2751L2.80153 2.69141" stroke="#FF0000" stroke-width="4" stroke-linecap="round"/>
<path d="M2.80176 19.2751L18.0012 2.69141" stroke="#FF0000" stroke-width="4" stroke-linecap="round"/>
</svg>
;
export default CompareSection;