import logo from './logo.svg';
import './App.css';
import TrustedBy from './components/TrustedBy';
import ProblemsSection from './components/ProblemsSection';
import SolutionsSection from './components/SolutionsSection';
import ComparisonTable from './components/ComparisonTable';
import UseCasesSection from './components/UseCasesSection';
import CompareSection from './components/CompareSection';
import AboutSection from './components/AboutSection';
import WhyUseDhaaraSmart from './components/WhyUseDhaaraSmart';
import WhyUseDhaaraSmartMob from './components/WhyUseDhaaraSmartMob';
import StoriesSection from './components/StoriesSection';
import FeaturesSection from './components/FeaturesSection';
import SelectDhaaraSize from './components/SelectDhaaraSize';
import ProductSpecifications from './components/ProductSpecifications';

import HeaderSection from './components/HeaderSection';
import HeaderSectionMobile from './components/HeaderSectionMobile';

import CompareSectionMob from './components/CompareSectionMob';
import FeaturesSection2 from './components/FeaturesSection2';
import ReCAPTCHA from 'react-google-recaptcha';
import FAQSection from './components/FAQSection';
import flag from './assets/header/flag.png';
import banner from './assets/header/banner2.png';
import inbuilt from './assets/header/wholeimage.png';
import bg from './assets/header/bg.PNG';
import logos from './assets/header/logo.png';
import bg2 from './assets/header/Group298.png';
import ellisecover from './assets/header/ellisecover.png';
import Modal from "./components/Modal";
import React, { useState } from "react";
function App() {
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);

  const [isLoading, setIsLoading] = useState(false);  // New state for loading spinner
  const [submitMessage, setSubmitMessage] = useState('');  // New state for submission response



  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const toggleModal1 = () => {
    setShowModal(false);
    setShowModal1(true);
  };
  const onClose1 = () => {
    setShowModal(false);  // Close the form modal
    setShowModal1(false);  // Close the success message modal
    setSubmitMessage('');  // Clear the error message
  };
  
  
  const onClose2 = () => {
    setShowModal(false);
    setShowModal1(false);
  };
  
  const onClose = () => {
    setShowModal(false);
  };
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    console.log('Captcha value:', value);
  };
 
 
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
  
    const form = e.target;
    const formData = new FormData(form);
    const currentUrl = window.location.href;  // Capture the current URL
    
    if (!captchaValue) {
      alert('Please complete the CAPTCHA');
      return;
    }
  
    if (form.checkValidity()) {
      setIsLoading(true);  // Show loading spinner
      setSubmitMessage('');  // Clear previous messages
  
      const formObject = Object.fromEntries(formData.entries());
  
      try {
        const response = await fetch('https://wti3kycad7ur57gctx5didbzpm0bghin.lambda-url.ap-south-1.on.aws/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            ...formObject,
            captchaValue,
            currentUrl,  // Add the current URL to the request body
          }),
        });
  
        if (response.ok && response.status === 200) {
          console.log('Form submitted successfully');
          toggleModal1();  // Show the success modal
        } else {
          setSubmitMessage('Form submission failed. Please try again.');
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setSubmitMessage('Form submission failed. Please try again.');
      } finally {
        setIsLoading(false);  // Hide loading spinner
      }
    } else {
      form.reportValidity();  // Trigger built-in HTML5 validation
    }
  };
  
  


  return (
    <div className="App">
      <div className="block lg:hidden sm:block">
        <HeaderSectionMobile show={showModal} toggleModal={toggleModal}/>
      </div>

      {/* Display only on laptop/desktop */}
      <div className="hidden lg:block sm:hidden">
        <HeaderSection show={showModal} toggleModal={toggleModal}/>
      </div>
     
    <div className="text-center font-sans bg-blue-50">
      {/* Other Sections Above */}
      
      <TrustedBy show={showModal} toggleModal={toggleModal}/>

      {/* Other Sections Below */}
    </div>



    <div className="text-center font-sans bg-blue-50">
      {/* Other Sections Above */}
      
      <ProblemsSection show={showModal} toggleModal={toggleModal}/>

      {/* Other Sections Below */}
    </div>




    <div className="text-center font-sans bg-blue-50">
      {/* Other Sections Above */}
      
      <SolutionsSection show={showModal} toggleModal={toggleModal}/>

      {/* Other Sections Below */}
    </div>
    <div className="text-center font-sans">
      {/* Other Sections Above */}
      
      <ComparisonTable show={showModal} toggleModal={toggleModal}/>

      {/* Other Sections Below */}
    </div>
    <div className="text-center font-sans bgradial">
      {/* Other Sections Above */}
      
      <UseCasesSection show={showModal} toggleModal={toggleModal}/>
      <AboutSection show={showModal} toggleModal={toggleModal}/>
      
      <div className="block lg:hidden sm:block">
      <CompareSectionMob show={showModal} toggleModal={toggleModal}/>
      </div>

      {/* Display only on laptop/desktop */}
      <div className="hidden lg:block sm:hidden">
      <CompareSection show={showModal} toggleModal={toggleModal}/>
      </div>
      <StoriesSection show={showModal} toggleModal={toggleModal}/>
      <FeaturesSection2 show={showModal} toggleModal={toggleModal}/>


      <div className="block lg:hidden sm:block">
      <WhyUseDhaaraSmartMob show={showModal} toggleModal={toggleModal}/>
      </div>

      {/* Display only on laptop/desktop */}
      <div className="hidden lg:block sm:hidden">
      <WhyUseDhaaraSmart show={showModal} toggleModal={toggleModal}/>
      </div>
     
      
    
      <ProductSpecifications show={showModal} toggleModal={toggleModal}/>
      <SelectDhaaraSize show={showModal} toggleModal={toggleModal}/>
      <FAQSection show={showModal} toggleModal={toggleModal}/>

 

<Modal show={showModal} onClose={toggleModal}>
 
<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          {/* Modal Content */}
          <div className="bg-white rounded-lg shadow-lg p-8 max-w-lg w-full">
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-xl font-bold headingtext">Get a call back</h2>
              <button onClick={toggleModal} className="text-gray-400 hover:text-gray-600">
                &#x2715; {/* Close icon */}
              </button>
            </div>
            <form onSubmit={handleSubmit}>

              <div className="mb-1 text-left">
                <label className="block faqtext text-xs font-normal mb-0">
                  Name <span className="text-red-500">*</span>
                </label>

                <input
               name="name"
                  type="text" required
                  placeholder="e.g. John Doe"
                  className="w-full px-2 py-1 text-xs border bgmodal rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-1 mt-3 text-left">
                <label className="block faqtext text-xs font-normal ">
                  Organization Name
                </label>
                <input
                  type="text"
                  name="organizationName"
                  
                  placeholder="e.g. ABC Industries"
                  className="w-full px-2 py-1 border text-xs bgmodal rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-1 mt-3  text-left">
                <label className="block faqtext text-xs font-normal">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                    name="email"
                  required
                  placeholder="e.g. john123@gmail.com"
                  className="w-full px-2 py-1 text-xs border bgmodal rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="mb-1 mt-3  text-left">
                <label className="block faqtext text-xs font-normal">
                  Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                  type="tel"
                  required
                  name="phone"
                  placeholder="e.g. +919876543210"
                  pattern="(\+?\d{1,3})?\d{10}"  // Allows an optional country code and ensures exactly 10 digits for the number
                  title="Please enter a valid phone number with 10 digits."
                  className="w-full px-2 py-1 text-xs border bgmodal rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />

              </div>
              <div className="mb-1 mt-3  text-left">
                <label className="block faqtext text-xs font-normal">
                  Brief Requirement
                </label>
                <input
                  type="text"
                  name="requirement"
                  
                  placeholder="e.g. For monitoring industrial water usage."
                  className="w-full px-2 py-1 text-xs border bgmodal rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="captcha-container">
        <ReCAPTCHA
          sitekey="6LfnI2UdAAAAAA7IsAJLN6SIANIbCkC1b2iBO_0f"
          onChange={handleCaptchaChange}
        />
      </div>
              <div className="flex items-center justify-between mt-4">
      <a
        href="https://wa.me/919281097232"
        className="text-blue-900 font-semibold underline"
      >
        WhatsApp at +91 92810 97232
      </a>
      <button
        type="submit"
        disabled={isLoading}  // Disable the button while loading
        className={`bg-yellow-500 text-black font-semibold px-6 py-2 rounded ${isLoading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-yellow-600'} focus:outline-none focus:ring-2 focus:ring-yellow-500`}
      >
        {isLoading ? <div className="loader"></div> : "Submit"}  {/* Loading Spinner or Submit Button */}
      </button>


    </div>
    {submitMessage && (
  <div className="text-red-500 text-sm mt-4">
    {submitMessage}  {/* Display the submit response message */}
  </div>
)}

            </form>
          </div>
        </div>
</Modal>



<Modal show={showModal1}>
<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-md w-full">
       
      <div className="flex justify-between items-center mb-6">
          <h2 className="text-5xl text-center font-bold text-blue-900"></h2>
          <button onClick={onClose2} className="text-gray-400 hover:text-gray-600">
            &#x2715; {/* Close icon */}
          </button>
        </div>
        <div className=" justify-between items-center mb-6">
          <h2 className="text-5xl text-center font-bold text-blue-900">Thank you!</h2>
         
        </div>
        <div className="flex flex-col items-center mb-6">
          {/* Green Checkmark */}

          <svg width="79" height="60" viewBox="0 0 79 60" fill="none" xmlns="http://www.w3.org/2000/svg"  className="w-20 h-20 mb-4">
<path d="M6 35.0134L25.1043 53.5177C25.3801 53.7848 25.8196 53.7799 26.0894 53.5067L73 6" stroke="#00A300" stroke-width="11" stroke-linecap="round"/>
</svg>

          
          <p className="text-lg font-semibold text-center">
            Your request has been successfully submitted
          </p>
        </div>
        <p className="text-gray-600 text-xs text-center mb-4">
          Our team will review your request and get back to you shortly.
          If you have any urgent queries, please contact us via
          <br />
          <a
            href="https://wa.me/919281097232"
            className="text-blue-900 font-semibold underline"
          >
            WhatsApp at +91 92810 97232
          </a>
        </p>
        <div className="flex justify-center">
          <button
            onClick={onClose1}
            className="bg-yellow-500 text-black font-semibold px-6 py-2 rounded hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500"
          >
            Go back
          </button>
        </div>
      </div>
    </div>
</Modal>







      {/* Other Sections Below */}
    </div>
    </div>
  );
}

export default App;
