import React from 'react';
import whyuse from '../assets/header/whyuse2.png';

const WhyUseDhaaraSmart = ({toggleModal}) => {
  return (
    <div className="text-center py-4 bg-white">
    <div
      style={{
        backgroundImage: `url(${whyuse})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',  // Set width to 100% of the parent container
    height: '560px',
      }}
    >
      <h2 className="text-2xl lg:text-3xl font-semibold headingtext mt-10 mb-10">
        Why use Dhaara Smart?
      </h2>

      <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
        <div className="flex flex-col space-y-6">


        <div className="text-end px-6" style={{
        marginLeft:'1px',
    marginRight: '150px',
    marginTop: '2px'}}>
    <h3 className="text-md font-semibold faqtext mb-2">Ultrasonic methodology sealing</h3>
    <p className="text-md text-gray-700">State-of-the-art technology ensuring higher satisfaction and accurate flow measurement.</p>
  </div>


  <div className="text-end px-6" style={{
        marginLeft:'0px',
    marginRight: '166px',
    marginTop: '43px'}}>
    <h3 className="text-md font-semibold faqtext mb-2">Battery</h3>
    <p className="text-md text-gray-700">Long-lasting batteries for continuous data collection, low-power design.</p>
  </div>


  <div className="text-end px-6" style={{
        marginLeft:'0px',
    marginRight: '147px',
    marginTop: '14px'}}>
    <h3 className="text-md font-semibold faqtext mb-2">Cast Iron</h3>
    <p className="text-md text-gray-700">Durable, good tensile strength, powder-coated finish for extended lifespan.</p>
  </div>





        {/*   <Feature
            title="Ultrasonic methodology sealing"
            description="State-of-the-art technology ensuring higher satisfaction and accurate flow measurement."
          />
          <Feature
            title="Battery"
            description="Long-lasting batteries for continuous data collection, low-power design."
          />
          <Feature
            title="Cast Iron"
            description="Durable, good tensile strength, powder-coated finish for extended lifespan."
          /> */}
        </div>
        <div className="flex flex-col space-y-6 " >
        <div className="text-left px-6" style={{  marginRight: '0px',
    marginLeft: '124px',
    marginTop: '-75px'}}>
    <h3 className="text-md font-semibold faqtext mb-2">IP68 enclosure</h3>
    <p className="text-md text-gray-700">All weather resistance for outdoor installations with integrity intact.</p>
  </div>

  <div className="text-left px-6" style={{  marginRight: '0px',
    marginLeft: '160px',
    marginTop: '44px'}}>
    <h3 className="text-md font-semibold faqtext mb-2">Patent 3958696</h3>
    <p className="text-md text-gray-700">Cutting-edge AI-based design, intuitive installation with accurate monitoring.</p>
  </div>


  <div className="text-left px-6" style={{  marginRight: '0px',
    marginLeft: '174px',
    marginTop: '20px'}}>
    <h3 className="text-md font-semibold faqtext mb-2">Tamper-proof design</h3>
    <p className="text-md text-gray-700">Security at highest standards, resilient to physical attempts of breach.</p>
  </div>
        {/*   <Features
            title="IP68 enclosure"
            description="All weather resistance for outdoor installations with integrity intact."
          />
          <Features
            title="Patent 3958696"
            description="Cutting-edge AI-based design, intuitive installation with accurate monitoring."
          />
          <Features
            title="Tamper-proof design"
            description="Security at highest standards, resilient to physical attempts of breach."
          /> */}
        </div>
      </div><br></br>
    
    </div>
    <button className=" text-black font-semibold py-2 px-4 rounded  mb35 " style={{background: '#FDB740',paddingLeft: '2.5rem',
    paddingRight: '2.5rem'
}} onClick={toggleModal}>
          Get a call back
        </button>
    </div>
  );
};

const Feature = ({ title, description }) => (
  <div className="text-end px-6">
    <h3 className="text-md font-semibold text-blue-900 mb-2">{title}</h3>
    <p className="text-md text-gray-700">{description}</p>
  </div>
);
const Features = ({ title, description }) => (
  <div className="text-left px-6">
    <h3 className="text-md font-semibold text-blue-900 mb-2">{title}</h3>
    <p className="text-md text-gray-700">{description}</p>
  </div>
);

export default WhyUseDhaaraSmart;
