import React from 'react';
import spec from '../assets/header/spec.PNG';
const ProductSpecifications = ({toggleModal}) => {
  return (
    <div className="text-center py-10 bgradial" style={{ backgroundImage: `url(${spec})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
    <div className="container mx-auto p-4 lg:pblsection lg:max-w-5xl lg:px-4">
      <h2 className="text-2xl lg:text-3xl font-semibold headingtext mb-10">Product specifications</h2>
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-10 text-left">
        <div>
          <Specification
            title="Certification"
            specs={[
              { label: 'Metrology', value: 'ISO 4064:2014 class 2 performance certification' },
              { label: 'Ingress protection', value: 'IP68' },
            ]}
          />
          <Specification
            title="Connectivity"
            specs={[
              { label: 'LTE(4G) (Frequency bands)', value: 'LTE-FDD - B1/B3/B5/B8 LTE-TDD - B34/B38/B39/B40/B41' },
              { label: 'GSM Frequency bands', value: 'GSM - B3/B8' },
            ]}
          />
          <Specification
            title="Display"
            specs={[
              { label: 'Display type', value: 'LCD' },
              { label: 'Display size', value: '60 x 40 (35) mm' },
            ]}
          />
          <Specification
            title="Power Supply"
            specs={[
              { label: 'Type', value: 'Li - SOCl2 primary battery' },
              { label: 'Voltage', value: '3.6 V' },
              { label: 'Capacity', value: '38 Ah' },
            ]}
          />
          <Specification
            title="Data"
            specs={[
              { label: 'Security', value: 'TLS v1.2' },
              { label: 'Communication protocol', value: 'MQTTS' },
              { label: 'Cloud', value: 'Amazon web services' },
            ]}
          />
          <Specification
            title="Rated operating conditions"
            specs={[
              { label: 'Measured over range', value: '-5 °C to +55 °C' },
              { label: 'Ambient temp. operating', value: 'Atmospheric Pressure and Reaction Resilience (WL-MW)' },
            ]}
          />
        </div>
        <div>
          <Specification
            title="Mechanical"
            specs={[
              { label: 'Enclosure weight', value: '' },
              { label: 'Enclosure dimension', value: '135x135x75.5 mm' },
              { label: 'Enclosure material', value: '' },
              { label: 'Casing material', value: 'Brass (DN25) Cast iron (DN40 and above)' },
              { label: 'Casing weight', value: 'DN25 to DN200 / 0.5kg to 28kg' },
              { label: 'Maximum admissible pressure (limited by transducers)', value: '' },
              { label: 'Mounting style', value: 'Threaded- DN25, DN40 Flanged - DN50, DN65, DN80, DN100, DN150, DN200' },
              { label: 'Installation orientation', value: 'Horizontal & vertical (upward direction)' },
              { label: 'Environmental classification', value: '' },
              { label: 'Electromagnetic environment', value: '135x135x75.5 mm' },
            ]}
          />
          <Specification
            title="Metrology"
            specs={[
              { label: 'Principle', value: 'Ultrasonic transit time' },
              { label: 'Transducer resonant frequency', value: '' },
              { label: 'Transducer channels/path', value: '' },
              { label: 'Calibration', value: '' },
              { label: 'Sensitivity class', value: '' },
              { label: 'Accuracy class', value: '' },
              { label: 'Installation orientation', value: 'Horizontal & vertical (upward direction)' },
              { label: 'Environmental classification', value: '' },
              { label: 'Electromagnetic environment', value: '' },
              { label: 'Meter temperature class', value: 'years' },
            ]}
          />
          <Specification
            title="Rated operating conditions"
            specs={[
              { label: 'Ambient temperature range', value: '+5 °C to +55 °C.' },
              { label: 'Ambient relative humidity range', value: '' },
              { label: 'Pressure range', value: '0.03 MPa to 1.6 MPa' },
              { label: 'Calibration', value: '' },
            ]}
          />
        </div>
      </div>
      <button className=" text-black font-semibold py-2 px-4 rounded  mb35 " style={{background: '#FDB740',paddingLeft: '2.5rem',
    paddingRight: '2.5rem', marginBottom:'0rem'
}} onClick={toggleModal}>
          Get a call back
        </button>    </div>
  </div>
  );
};

const Specification = ({ title, specs }) => (
  <div className="mb-4">
    <h3 className="text-lg font-semibold textspexc mb-4 mt-4">{title}</h3>
    {specs.map((spec, index) => (
      <div className="grid grid-cols-2 border-b py-1" key={index}>
        <div className="textspexc text-xs">{spec.label}</div>
        <div className="textspexc text-xs text-end">{spec.value}</div>
      </div>
    ))}
  </div>
);

export default ProductSpecifications;