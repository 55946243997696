import React, { useState, useEffect } from 'react';
import MarqueeComponent from './MarqueeComponent';
import cas from '../assets/header/cas.png';
import adani from '../assets/header/adani.png';
import godrej from '../assets/header/godrej.png';
import ultratech from '../assets/header/ultratech.png';
import jcb from '../assets/header/jcb.png';
import tcs from '../assets/header/tcs.png';
import voltas from '../assets/header/voltas.png';
import molsoncoors from '../assets/header/molsoncoors.png';
import logomobile from '../assets/header/logomobile.png';
import Asset from '../assets/header/Asset.png';
function Counter({ end, duration }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const endValue = parseInt(end);
    const incrementTime = Math.abs(Math.floor(duration / endValue));
    
    const timer = setInterval(() => {
      start += 4;
      setCount(start);
      if (start === endValue) {
        clearInterval(timer);
      }
    }, incrementTime);

    return () => clearInterval(timer);
  }, [end, duration]);

  return <span>{count.toLocaleString()}</span>;
}
function Counter2({ end, duration }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const endValue = parseInt(end);
    const incrementTime = Math.abs(Math.floor(duration / endValue));
    
    const timer = setInterval(() => {
      start += 1;
      setCount(start);
      if (start === endValue) {
        clearInterval(timer);
      }
    }, incrementTime);

    return () => clearInterval(timer);
  }, [end, duration]);

  return <span>{count.toLocaleString()}</span>;
}
const TrustedBy = () => {
  return (
    <div className="text-center py-10 bg-white" style={{marginBottom:'2rem'}}>
      <h2 className="text-xl lg:text-2xl font-semibold mb-6 text-greys-900">Trusted by</h2>
      <div className="flex justify-center items-center m75 flex-wrap mb-1 space-x-1">
      
      <div className="block lg:hidden sm:block">
    
      <img src={logomobile} alt="Adani" />
      </div>

      {/* Display  <MarqueeComponent />only on laptop/desktop */}
      <div className="hidden lg:block sm:hidden">
      <img src={Asset} alt="Adani" />
      </div>
     
     
        {/* <img src={adani} alt="Adani" className="h-10 mb-4" />
        <img src={cas} alt="Castrol" className="h-10 mb-4" />
        <img src={godrej} alt="Godrej" className="h-10 mb-4" />
        <img src={ultratech} alt="UltraTech" className="h-10 mb-4" />
        <img src={jcb} alt="JCB" className="h-10 mb-4" />
        <img src={tcs} alt="TCS" className="h-10 mb-4" />
        <img src={voltas} alt="Tata" className="h-10 mb-4" />
        <img src={molsoncoors} alt="Voltas" className="h-10 mb-4" />
        <img src={ceramat} alt="Molson Coors" className="h-10 mb-4" /> */}
      
      </div>
      <div className="grid grid-cols-3 md:grid-cols-3 gap-4 justify-center items-center max-w-4xl mx-auto ">
        <div className="text-center mx-2 px-2 lg:mx-10 lg:px-5">
          <h3 className="text-2xl xs:text-2xl sm:text-lg md:text-5xl font-bold text-blue-900"> <Counter2 end="15" duration={5000} />Bn</h3>
          <p className="text-xs  text-gray-600" style={{lineHeight:'0.75rem'}}>Liters of water measured</p>
        </div>
        <div className="text-center mx-2 px-2 lg:mx-10 lg:px-5">
          <h3 className="text-2xl xs:text-xs sm:text-lg md:text-5xl   font-bold text-blue-900"><Counter end="1000" duration={10000} />+</h3>
          <p className=" text-xs text-gray-600" style={{lineHeight:'0.75rem'}}>Satisfied businesses</p>
        </div>
        <div className="text-center mx-2 px-2 lg:mx-10 lg:px-5">
          <h3 className="text-2xl xs:text-xs sm:text-lg md:text-5xl   font-bold text-blue-900"><Counter end="4000" duration={10000} />+</h3>
          <p className="text-xs text-gray-600" style={{lineHeight:'0.75rem'}}>Borewells under monitoring</p>
        </div>
      </div>
    </div>
  );
};

export default TrustedBy;