import React from 'react';
import dn25 from '../assets/header/dn25.png';
import solution from '../assets/header/solution.png';
import solution2 from '../assets/header/solutions2.png';

const SolutionsSection = ({toggleModal}) => {
  return (
    <div className="text-center py-10 bg-blue-50">
      
      <div className="grid  max-w-5xl mx-auto">
      <div className="block lg:hidden sm:block px-4">
      <h2 className="text-xl lg:text-3xl font-bold headingtext mb-2">
        Dhaara Smart offers solutions to these challenges
      </h2>
      <p className="headingtext mb-10 text-sm">
        By using Dhaara Smart, businesses can <strong>reduce water wastage by <br></br>up to 25%</strong> and save significantly on water bills.
      </p>
      <img src={solution2} className='w-full' alt='Solutions'/>
      </div>

      {/* Display only on laptop/desktop */}
      <div className="hidden lg:block sm:hidden">
      <h2 className="text-2xl lg:text-3xl font-bold headingtext mb-2">
        Dhaara Smart offers solutions to these challenges
      </h2>
      <p className="headingtext mb-10">
        By using Dhaara Smart, businesses can <strong>reduce water wastage by <br></br>up to 25%</strong> and save significantly on water bills.
      </p>
      <img src={solution} className='w-full' alt='Solutions'/>
      </div>
   
      
   
       {/*  <SolutionCard
          title="Smart tamper detection"
          description="Alerts for any misuse or manipulation, ensuring safer water management."
        />
        <SolutionCard
          title="Accurate data & In-House calibration"
          description="AI technology and calibration for accurate water monitoring."
        />
        <SolutionCard
          title="Easy installation"
          description="Battery-operated, plug & play device with inbuilt GPRS/4G telemetry for hassle-free setup."
        />
        <SolutionCard
          title="Achieve water efficiency & compliance"
          description="Analyze usage patterns, identify excess usage, and ensure CGWA compliance with online monitoring and alerts."
        /> 
        <SolutionCards
          title=""
          description=""
        />



          <div className="flex flex-col items-start p-4 bg-whitex shadow-xl rounded-xl sm:lowcard" >
            <div className="flex items-center mb-2">
              <div className="w-6 h-6 flex items-center justify-center bg-green-400 rounded-full mr-2">
                <svg
                  className="w-4 h-4 text-white"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                  style={{width: "2rem"}}
                >
                
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5l7 7-7 7"
                  ></path>
                </svg>
              </div>
              <h2 className="text-left text-2xl font-bold text-blue-900">Super easy reporting & continuous support</h2>
            </div>
            <p className="text-gray-700 text-left text-sm">Effortless reporting and 24/5 online assistance for comprehensive water budgeting support.</p>
          </div>

 */}

        
       
      </div>
      <br></br>
      <button className=" text-black font-semibold py-2 px-4 rounded  mb35 " style={{background: '#FDB740',paddingLeft: '2.5rem',
    paddingRight: '2.5rem',marginTop:'0rem',marginBottom:'1rem'
}} onClick={toggleModal}>
          Get a call back
        </button>
    </div>
  );
};
/* Rectangle 1775 */


const SolutionCard = ({ title, description }) => (
  <>
   <div className="flex flex-col items-start p-4 bg-whitex shadow-xl rounded-xl">
      <div className="flex items-center mb-2">
        <div className="w-6 h-6 flex items-center justify-center bg-green-400 rounded-full mr-2">
          <svg
            className="w-4 h-4 text-white"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            style={{width: "2rem"}}
          >
          
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 5l7 7-7 7"
            ></path>
          </svg>
        </div>
        <h2 className="text-left text-2xl font-bold text-blue-900">{title}</h2>
      </div>
      <p className="text-gray-700 text-left text-sm">{description}</p>
    </div>
 
  </>
);

const SolutionCards = ({ title, description }) => (
  <div className="p-5  flex items-start space-x-4">
   
    <div>
     <img src={dn25} className='w-full'/>
    </div>
  </div>
);

export default SolutionsSection;