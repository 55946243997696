import React, { useState } from 'react';
import faq from '../assets/header/faq.PNG';
import sidefooter from '../assets/header/sidefooter.png';

const FAQSection = ({ toggleModal }) => {
  const [openFAQIndex, setOpenFAQIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQIndex(openFAQIndex === index ? null : index);
  };

  const faqs = [
    { question: "Pricing - Why is the device costly?", answer: "Our meter offers the best value for money, meeting all CGWA specifications. It includes advanced features like dual SIM, tamper-proof design, and extensive after-sales support." },
    { question: "Battery Make and Specification", answer: "Our meter's battery life ranges from 3 to 5 years, depending on network strength and transmission frequency. It uses high-quality batteries designed for long-term use." },
    { question: "Product Specifications", answer: "Our meter is available in sizes from DN25 to DN250 (1 inch to 10 inches). It features an IP68 rating, dual SIM for reliable data transmission, and local data storage." },
    { question: "What is IP68 in Detail?", answer: "IP68 certification means our meter is dust-tight and can withstand being submerged in water up to 1.5 meters for 30 minutes, ensuring durability and reliability." },
    { question: "Is Calibration Really Needed?", answer: "Yes, calibration ensures accurate measurements and compliance with regulatory standards. Our service engineers handle all calibration needs." },
    { question: "What Are the Benefits?", answer: "Benefits include compliance with CGWA, tamper-proof design, long battery life, easy installation, and excellent after-sales support." },
    { question: "Comparison Between Electromagnetic & Ultrasonic Meters", answer: "Ultrasonic meters use less power, do not require an external power supply, and work better with various water types (groundwater, RO, and distilled)." },
    { question: "Want to Know What is Ultrasonic Technology?", answer: "Ultrasonic technology measures water flow using high-frequency sound waves, calculating the time difference between upstream and downstream signals." },
    { question: "How to use the dashboard?", answer: "Our dashboard provides comprehensive data, including flow rates, consumption reports, and alerts. It supports data export in Excel format for easy analysis." },
    { question: "ISO 4064", answer: "ISO 4064 is an international standard for water meters, covering metrological and technical requirements, test methods, and installation guidelines. Our meters comply with these standards,ensuring accurate and reliable performance." },
  ];

  // Split FAQs into two columns
  const middleIndex = Math.ceil(faqs.length / 2);
  const leftColumnFaqs = faqs.slice(0, middleIndex);
  const rightColumnFaqs = faqs.slice(middleIndex);

  return (
    <div className="text-center pt-10" style={{
      backgroundImage: `url(${faq})`,
      backgroundSize: "cover",
      backgroundPosition: "center",
    }}>
      <div className="container mx-auto lg:max-w-5xl lg:px-4 faq-column">
        <h2 className="text-left text-2xl font-semibold text-blue-900 mb-4">Frequently Asked Questions</h2>
        <p className="text-left text-gray-700 mb-8">
          Quick answers to common inquiries about the Dhaara Smart Ultrasonic Water Flowmeter. Contact us for more details.
        </p>

        <div className="faq-columns">
          <div className="faq-column">
            {leftColumnFaqs.map((faq, index) => (
              <div key={index} className={`p-3 faqbg`}>
                <button
                  className="w-full text-left faqtext text-sm font-semibold flex justify-between items-center"
                  onClick={() => toggleFAQ(index)}
                >
                  {faq.question}
                  <svg className={openFAQIndex === index ? 'transform rotate-180' : ''} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#F0F0F0" />
                    <path opacity="0.8" d="M8 10L13 15L18 10" stroke="#444346" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </button>
                {openFAQIndex === index && (
                  <div className={`faq-answer open`}>
                    <p className="mt-2 text-left text-sm text-gray-700">
                      {faq.answer}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>

          <div className="faq-column">
            {rightColumnFaqs.map((faq, index) => (
              <div key={index + middleIndex} className={`p-3 faqbg`}>
                <button
                  className="w-full text-left faqtext text-sm font-semibold flex justify-between items-center"
                  onClick={() => toggleFAQ(index + middleIndex)}
                >
                  {faq.question}
                  <svg className={openFAQIndex === index + middleIndex ? 'transform rotate-180' : ''} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="12.5" cy="12.5" r="12.5" fill="#F0F0F0" />
                    <path opacity="0.8" d="M8 10L13 15L18 10" stroke="#444346" strokeWidth="1.5" strokeLinecap="round" />
                  </svg>
                </button>
                {openFAQIndex === index + middleIndex && (
                  <div className={`faq-answer open`}>
                    <p className="mt-2 text-left text-sm text-gray-700">
                      {faq.answer}
                    </p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        
        <button className="text-black font-semibold py-2 px-4 rounded mb35" style={{ background: '#FDB740', paddingLeft: '2.5rem', paddingRight: '2.5rem' }} onClick={toggleModal}>
          Get a call back
        </button>
        <div className="text-center align-center">
          <img src={sidefooter} alt="" className='w-full' />
        </div>
      </div>
    </div>
  );
};

export default FAQSection;
