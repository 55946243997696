import React from 'react';
import group3 from '../assets/header/d3.svg';
import group1 from '../assets/header/d1.svg';
import group2 from '../assets/header/d2.svg';
const ComparisonTable = ({toggleModal}) => {
  return (
    <div className="text-center py-10 bg-[#2b3c73] text-white">
      <h2 className="text-2xl lg:text-3xl font-semibold mb-2" style={{    marginTop: '1rem',}}>
        Dhaara Smart, A smart choice!
      </h2>
      <div className="container mx-auto max-w-4xl  grid grid-cols-1 overflow-x-auto">
        <table className="min-w-full  rounded-lg divide-y divide-blue-700 borderbg">
          <thead>
            <tr className='align-center'>
              <th className="px-4 py-2 text-left font-semibold" style={{paddingTop: '20%'}}>&nbsp;</th>
              <th className="lg:px-4 lg:py-2 align-center">
              <div className='align-center'> <img src={group1}  className="w-full h-auto lg:p-6 sm:p-0 md:p-4 lg:p-6" alt='Dhaara Samrt Meter' /></div>
              </th>
              <th className="lg:px-4 lg:py-2 align-center"> 


              <div className='align-center'> 
              <img src={group2}  className="w-full h-auto px-0 lg:p-6 sm:p-2 md:p-4 lg:p-6" alt='Digital Meter' />


              </div>
             </th>
              <th className="lg:px-4 lg:py-2 align-center"> <div className='align-center'> 
              <img src={group3}  className="w-full h-auto px-0 lg:p-6 sm:p-2 md:p-4 lg:p-6" alt='Manual Meter' />
              </div></th>
            </tr>
            <tr className='align-center'>
              <th className="px-3 lg:px-4 py-2 text-left text-md lg:text-xl sm:text-smd md:text-sm font-semibold" style={{paddingTop: '0%'}}>Description</th>
              <th className="px-0 lg:px-4 py-2 text-md lg:text-xl sm:text-smd md:text-sm  align-center font-semibold" style={{paddingTop: '0%'}}>Dhaara Smart Meter</th>

              <th className="px-0 lg:px-4 py-2 text-md lg:text-xl sm:text-smd md:text-sm  align-center font-normal" style={{paddingTop: '0%'}}>Digital Meter</th>
              <th className="px-0 lg:px-4 py-2 text-md lg:text-xl sm:text-smd md:text-sm  align-center font-normal" style={{paddingTop: '0%'}}>Manual Meter</th>
              
            </tr>
          </thead>
          <tbody className="divide-y divide-blue-700">
            <tr>
              <td className="px-4 py-2  text-left text-md font-normal  w-1/5">Advanced technology</td>
              <td className="px-4 py-2  align-center  w-1/6"> <div className="flex justify-center items-center h-full"> <svg width="20" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 11.3688L7.91552 17.0268C8.19052 17.2898 8.62526 17.2849 8.89434 17.0159L23.9101 1.99976" stroke="#00FF00" stroke-width="4" stroke-linecap="round"/>
</svg></div></td>
            <td className="px-4 py-2 w-1/6"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
              <td className="px-4 py-2 w-1/6"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
            </tr>
            <tr>
              <td className="px-4 py-2 text-left">High accuracy</td>
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CheckIcon /></div></td>
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CheckIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
            </tr>
            <tr>
              <td className="px-4 py-2  text-left">Inbuilt power supply</td>
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CheckIcon /></div></td>
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
            </tr>
            <tr>
              <td className="px-4 py-2 text-left">In-built telemetry</td>
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CheckIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
            </tr>
            <tr>
              <td className="px-4 py-2  text-left">Tamper proof</td>
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CheckIcon /></div></td>
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
            </tr>
            <tr>
              <td className="px-4 py-2  text-left">Low maintenance</td>
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CheckIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
            </tr>
            <tr>
              <td className="px-4 py-2 text-left">Online logbook</td>
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CheckIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
            </tr>
            <tr>
              <td className="px-4 py-2  text-left">Easy installation</td>
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CheckIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
            </tr>
            <tr>
              <td className="px-4 py-2  text-left">Smart features</td>
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CheckIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
              <td className="px-4 py-2"><div className="flex justify-center items-center h-full"> <CrossIcon /></div></td>
             
            </tr>
          </tbody>
        </table>
      </div>
       <button className=" text-black font-semibold py-2 px-4 rounded  mb35 " style={{background: '#FDB740',paddingLeft: '2.5rem',
    paddingRight: '2.5rem',marginBottom:'1.3rem'
}} onClick={toggleModal}>
          Get a call back
        </button>
    </div>
  );
};

const CheckIcon = () => <svg width="20" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 11.3688L7.91552 17.0268C8.19052 17.2898 8.62526 17.2849 8.89434 17.0159L23.9101 1.99976" stroke="#00FF00" stroke-width="4" stroke-linecap="round"/>
</svg>
;
const CrossIcon = () => <svg width="20" height="20" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18.001 19.2751L2.80153 2.69141" stroke="#FF0000" stroke-width="4" stroke-linecap="round"/>
<path d="M2.80176 19.2751L18.0012 2.69141" stroke="#FF0000" stroke-width="4" stroke-linecap="round"/>
</svg>
;

export default ComparisonTable;