import React from 'react';

import adani from '../assets/header/1t.png';
import filterx from '../assets/header/2t.png';
import dps from '../assets/header/3t.png';
import ckbirla from '../assets/header/4t.png';
import bg from '../assets/header/Group 165.png';
import ph1 from '../assets/header/Ellipse 62.png';
import ph2 from '../assets/header/Ellipse 63.png';
import ph3 from '../assets/header/Ellipse 64v.png';
import ph4 from '../assets/header/Ellipse 64.png';

import rectbg from '../assets/header/rectbg.png';



const StoriesSection = ({toggleModal}) => {
  return (
    <div className="text-center py-10 bg-blue-50" style={{paddingTop:'1rem'}}>
    <h2 className="text-2xl lg:text-3xl font-semibold headingtext mb-10" style={{marginTop:'1rem',marginBottom:' 3.5rem'}}>Stories of satisfaction</h2>
  
   



    <div className="block lg:hidden sm:block">
    <div className="px-2 grid gap-2  grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
    <StoryCards
        company={adani}
        profile={ph1}
        subtitle="Water Balancing"
        name="Suresh Babu"
        title="<i>SVP & Advisor, Adani</i>"
        description="As Adani group we wanted to become water positive <p>Airport</p>"
        desc="This is the baby step we have taken. We have started installing the water meter. This water meter is a telemetry kind of water meter and we get the data online to the system, and through this we can able to do the <i><b>water balancing.</b></i>"
      />
      <StoryCards
        company={filterx}
        profile={ph2}
        subtitle="saving 30% Water"
        name="Manikant Jha"
        title="<i>Plant Head, Filtrex</i>"
        description="We see a possibility of saving 30% Water.<br></br>"
        desc="We assumed our water consumption was low but the data from Kritsnam's Dhaara Smart broke a lot of our myths. Based on the last 2 months data, we see a possibility of  <i><b>saving 30% Water.</b></i>"
      />
      <StoryCards
        company={dps}
        profile={ph3}
        subtitle="deep perspective into our water consumption patterns"
        name="Divya Dwivedi"
        title="<i>Principal, DPS <br>Nagpur</i>"
        description="Dhaara flow meter is a wonderful gadget.<br></br>"
        desc="The data generated by Dhaara gives us a <i><b>deep perspective into our water consumption patterns</b></i>, tells us how we can improve, and gives us direction."
      />
      <StoryCards
        company={ckbirla}
        profile={ph4}
        subtitle="CGWA compliant"
        name="Om Gunjan"
        title="<i>Head Eng, CK Birla</i>"
        description="We measure our water consumption using Dhaara ultrasonic flow meter"
        desc="It's <i><b>CGWA compliant,</b></i> compact, works on batteries, and gives us daily, weekly, and monthly data of our water consumption. We analyze this data and take required steps."
      />
      </div>
    </div>
      {/* Display only on laptop/desktop */}
      <div className="hidden lg:block sm:hidden">
      <div className="grid gap-6 max-w-6xl mx-auto px-2 grid-cols-2  grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
      <StoryCard
        company={adani}
        profile={ph1}
        subtitle="Water Balancing"
        name="Suresh Babu"
        title="<i>SVP & Advisor, Adani</i>"
        description="As Adani group we wanted to become water positive <p>Airport</p>"
        desc="This is the baby step we have taken. We have started installing the water meter. This water meter is a telemetry kind of water meter and we get the data online to the system, and through this we can able to do the <i><b>water balancing.</b></i>"
      />
      <StoryCard
        company={filterx}
        profile={ph2}
        subtitle="saving 30% Water"
        name="Manikant Jha"
        title="<i>Plant Head, Filtrex</i>"
        description="We see a possibility of saving 30% Water.<br></br>"
        desc="We assumed our water consumption was low but the data from Kritsnam's Dhaara Smart broke a lot of our myths. Based on the last 2 months data, we see a possibility of  <i><b>saving 30% Water.</b></i>"
      />
      <StoryCard
        company={dps}
        profile={ph3}
        subtitle="deep perspective into our water consumption patterns"
        name="Divya Dwivedi"
        title="<i>Principal, DPS Nagpur</i>"
        description="Dhaara flow meter is a wonderful gadget.<br></br>"
        desc="The data generated by Dhaara gives us a <i><b>deep perspective into our water consumption patterns</b></i>, tells us how we can improve, and gives us direction."
      />
      <StoryCard
        company={ckbirla}
        profile={ph4}
        subtitle="CGWA compliant"
        name="Om Gunjan"
        title="<i>Head Eng, CK Birla</i>"
        description="We measure our water consumption using Dhaara ultrasonic flow meter"
        desc="It's <i><b>CGWA compliant,</b></i> compact, works on batteries, and gives us daily, weekly, and monthly data of our water consumption. We analyze this data and take required steps."
      />
      </div>

</div>



    
    <button className=" text-black font-semibold py-2 px-4 rounded  mb35 " style={{background: '#FDB740',paddingLeft: '2.5rem',
    paddingRight: '2.5rem',marginBottom:'0rem'
}} onClick={toggleModal}>
          Get a call back
        </button> </div>
  );
};

const StoryCard = ({ company, profile,subtitle,name, desc,title, description }) => (

  <div className="p-5 max-w-sm mx-auto bg-cover bg-center" style={{
  
    backgroundColor: '#f9fcff',
    border: '1px solid #cbced2',
    borderRadius: '15px',
  }}>
    <div className="text-center mb-3">
      <img src={company} alt={company} className="mx-auto mb-1" style={{ height: '50px' }} />
 {/* Make sure this is visible on mobile if needed */}
    </div>
    
    <div className="text-center mb-3">
      <svg width="216" height="46" viewBox="0 0 216 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M116.51 20.709H107.647V12.7988C107.631 10.3574 108.404 8.4694 109.967 7.13477C111.285 6.01172 113.043 5.12467 115.24 4.47363L116.485 7.11035C114.418 7.87533 113.189 8.77865 112.799 9.82031C112.604 10.3086 112.514 10.8945 112.53 11.5781H116.51V20.709ZM104.815 20.709H95.9531V12.7988C95.9531 10.3574 96.7344 8.4694 98.2969 7.13477C99.6152 6.01172 101.365 5.12467 103.546 4.47363L104.767 7.11035C102.732 7.90788 101.52 8.8112 101.129 9.82031C100.934 10.3086 100.836 10.8945 100.836 11.5781H104.815V20.709Z" fill="#FDB740"/>
        <line x1="132" y1="17.0003" x2="216" y2="17.0003" stroke="#2B3C73" stroke-opacity="0.29"/>
        <line x1="4.24617e-08" y1="17.0003" x2="84" y2="17.0003" stroke="#2B3C73" stroke-opacity="0.29"/>
      </svg>
    </div>
    
    <div className="flex items-start justify-left my-3">
      <img src={profile} alt="User" className="w-12 h-12 rounded-full border-2 border-gray-300" />
      <div className="ml-3 text-left" style={{marginTop:'2%'}}>
        <div className="text-xs font-semibold">{name}</div>
        <div className="text-xs text-gray-500"  dangerouslySetInnerHTML={{ __html: title }}></div>
      </div>
    </div>
   
    <p className="text-left text-sm faqtext font-semibold mb-4 mt-3" style={{    lineHeight: '1.2rem',}} dangerouslySetInnerHTML={{ __html: description }}></p>
    <p className="text-left text-sm faqtext mt-4 mb-4" dangerouslySetInnerHTML={{ __html: desc }}></p>
</div>
 
);

const StoryCards = ({ company, profile, subtitle, name, desc, title, description }) => (
  <div
    className="p-2  bg-cover bg-center"
    style={{
      backgroundColor: '#f9fcff',
      border: '1px solid #cbced2',
      borderRadius: '15px',
      margin: '10px',
    }}
  >
    <div className="text-center mb-1 mt-2">
      <img
        src={company}
        alt={company}
        className="mx-auto mb-1"
        style={{ height: '25px' }}
      />
    </div>

    <div className="text-center mb-1 mt-3">
      <svg
        width="160"
        height="26"
        viewBox="0 0 216 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M116.51 20.709H107.647V12.7988C107.631 10.3574 108.404 8.4694 109.967 7.13477C111.285 6.01172 113.043 5.12467 115.24 4.47363L116.485 7.11035C114.418 7.87533 113.189 8.77865 112.799 9.82031C112.604 10.3086 112.514 10.8945 112.53 11.5781H116.51V20.709ZM104.815 20.709H95.9531V12.7988C95.9531 10.3574 96.7344 8.4694 98.2969 7.13477C99.6152 6.01172 101.365 5.12467 103.546 4.47363L104.767 7.11035C102.732 7.90788 101.52 8.8112 101.129 9.82031C100.934 10.3086 100.836 10.8945 100.836 11.5781H104.815V20.709Z"
          fill="#FDB740"
        />
        <line
          x1="132"
          y1="17.0003"
          x2="216"
          y2="17.0003"
          stroke="#2B3C73"
          stroke-opacity="0.29"
        />
        <line
          x1="4.24617e-08"
          y1="17.0003"
          x2="84"
          y2="17.0003"
          stroke="#2B3C73"
          stroke-opacity="0.29"
        />
      </svg>
    </div>

    <div className="flex items-start justify-left my-1">
      <img
        src={profile}
        alt="User"
        className="w-12 h-12 rounded-full border-2 border-gray-300"
      />
      <div className="ml-3 text-left" style={{ marginTop: '3%' }}>
        <div className="text-xs font-semibold">{name}</div>
        <div
          className="text-xs text-gray-500"
          dangerouslySetInnerHTML={{ __html: title }}
        ></div>
      </div>
    </div>

    <p
      className="text-left faqtext text-xs font-semibold mb-2 mt-3"
      style={{ lineHeight: '0.8rem' }}
      dangerouslySetInnerHTML={{ __html: description }}
    ></p>
    <p
      className="text-left text-xs faqtext mt-4 mb-4"
      dangerouslySetInnerHTML={{ __html: desc }}
    ></p>
  </div>
);
export default StoriesSection;