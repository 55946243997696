import React from 'react';

import ceramat from '../assets/header/With bg7-02 2.png';


const SelectDhaaraSize = ({toggleModal}) => {
  return (
    <div className="align-center py-10 bg-light">
      
      <h2 className="text-xl lg:text-2xl  font-bold headingtext mb-1">Select the ideal Dhaara size for your application</h2>
      


      <div className="max-w-5xl mx-auto">
        <img
          src={ceramat}
          alt="Dhaara Sizes"
          className="w-full h-auto mb-1"
        />
      </div>
      <button className=" text-black font-semibold py-2 px-4 rounded  mb35 " style={{background: '#FDB740',paddingLeft: '2.5rem',
    paddingRight: '2.5rem'
}} onClick={toggleModal}>
          Get a call back
        </button> </div>
  );
}

export default SelectDhaaraSize;