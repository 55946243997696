import React from 'react';
const problems = [
  {
    title: "Non-compliance with CGWA regulations",
    description: "Risk of non-compliance with CGWA mandates due to inaccurate water usage data."
  },
  {
    title: "Inaccurate meter<br>readings",
    description: "Traditional meters provide incorrect readings, leading to overpayment and inefficiency."
  },
  {
    title: "Difficulty obtaining exemptions and NOCs",
    description: "Challenges in securing exemptions and No Objection Certificates (NOCs) because of incorrect readings."
  },

];
const problems1 = [
 
  {
    title: "",
    description: ""
  },
  {
    title: "",
    description: ""
  },
  {
    title: "",
    description: ""
  },


 
];
const problems2 = [ {
    title: "Lack of real-time <br>monitoring",
    description: "Inability to detect leaks and abnormal usage without continuous <br>monitoring."
  },
  {
    title: "Penalties for non-compliance",
    description: "Potential fines and penalties from failing to meet CGWA regulations."
  },
  {
    title: "Manual reporting<br> hassles",
    description: "Manual data collection is prone to errors and delays, reducing data accuracy and operational efficiency."
  }
];
const ProblemsSection = ({toggleModal}) => {
  return (
    <div className="text-center py-10 bg-blue-50">
    <h2 className="text-2xl lg:text-3xl font-bold headingtext mb-2">
      Are you facing these problems?
    </h2>
    <p className="headingtext mb-10">
      <strong>70% of businesses </strong>
      <strong>overpay</strong> on water usage
    </p>
    <div className="block lg:hidden sm:block">
    <div className="grid grid-cols-2 md:grid-cols-2  gap-3  mx-auto px-4">


    <div className="flex flex-col-1 items-center space-y-4 w-full h-full">

    <div className="p-2 bg-white rounded-lg shadow-lg z-10 flex flex-col h-full" >
    <div className="flex items-center">
    <div>
      <svg width="6" height="30" viewBox="0 0 6 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="3" y1="51" x2="3" y2="-1.31134e-07" stroke="#FDB73F" stroke-opacity="0.71" stroke-width="6"/>
      </svg>
    </div>
    <div>
      <h3 className="headingtext ml-2 font-bold text-sm text-left" style={{lineHeight:'1.2rem'}}>Non-compliance with <br></br>CGWA regulations</h3>
    </div>
    </div>
    <p className="text-left text-xs faqtext mt-2">
    Risk of non-compliance with CGWA mandates due to inaccurate water usage data.

    </p>

    </div>

    </div>



<div className="flex flex-col-1 items-center space-y-4 w-full max-h-full">

<div className="p-2 bg-white rounded-lg shadow-lg z-10 flex flex-col h-full" >
<div className="flex items-center">
<div>
  <svg width="6" height="30" viewBox="0 0 6 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="3" y1="51" x2="3" y2="-1.31134e-07" stroke="#FDB73F" stroke-opacity="0.71" stroke-width="6"/>
  </svg>
</div>
<div>
  <h3 className="headingtext ml-2 font-bold text-sm text-left" style={{lineHeight:'1.2rem'}}>Inaccurate meter <br></br>readings</h3>
</div>
</div>
<p className="text-left text-xs faqtext mt-2">
Traditional meters provide incorrect readings, leading to overpayment and inefficiency.

</p>
</div>

</div>



<div className="flex flex-col-1 items-center space-y-2 w-full max-h-full">
        {/*   <ProblemCard
            clasnew="bright"
            title="Difficulty obtaining exemptions and NOCs"
            description="Challenges in securing exemptions and No Objection Certificates (NOCs) because of incorrect readings."
          /> */}
          
        <div className="flex flex-col items-center space-y-4 w-full h-full">

          <div className="p-2 bg-white rounded-lg shadow-lg z-10 flex flex-col h-full">
          <div className="flex items-center">
          <div>
            <svg width="6" height="30" viewBox="0 0 6 51" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line x1="3" y1="51" x2="3" y2="-1.31134e-07" stroke="#FDB73F" stroke-opacity="0.71" stroke-width="6"/>
            </svg>
          </div>
          <div>
            <h3 className="headingtext ml-2 font-bold text-sm text-left" style={{lineHeight:'1rem'}}>Difficulty obtaining <br></br>exemptions and NOCs</h3>
          </div>
          </div>
          <p className="text-left text-xs faqtext mt-2">
          Challenges in securing exemptions and No Objection Certificates (NOCs) because of incorrect readings.
         
          </p>
          </div>

          </div>

        </div>




        <div className="flex flex-col-1 items-center space-y-4 w-full max-h-full">

<div className="p-2 bg-white rounded-lg shadow-lg z-10 flex flex-col h-full">
<div className="flex items-center">
<div>
  <svg width="6" height="30" viewBox="0 0 6 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="3" y1="51" x2="3" y2="-1.31134e-07" stroke="#FDB73F" stroke-opacity="0.71" stroke-width="6"/>
  </svg>
</div>
<div>
  <h3 className="headingtext ml-2 font-bold text-sm text-left" style={{lineHeight:'1.1rem'}}>Lack of real-time <br></br>monitoring</h3>
</div>
</div>
<p className="text-left text-xs faqtext mt-2">
Inability to detect leaks and abnormal usage without continuous monitoring.

</p>
</div>

</div>


<div className="flex flex-col-1 items-center space-y-4 w-full max-h-full">

<div className="p-2 bg-white rounded-lg shadow-lg z-10 flex flex-col h-full">
<div className="flex items-center">
<div>
  <svg width="6" height="30" viewBox="0 0 6 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="3" y1="51" x2="3" y2="-1.31134e-07" stroke="#FDB73F" stroke-opacity="0.71" stroke-width="6"/>
  </svg>
</div>
<div>
  <h3 className="headingtext ml-2 font-bold text-sm text-left" style={{lineHeight:'1.1rem'}}>Penalties for non- <br></br>compliance</h3>
</div>
</div>
<p className="text-left text-xs faqtext mt-2">
Potential fines and penalties from failing   to meet CGWA regulations.</p>

</div>

</div>


<div className="flex flex-col-1 items-center space-y-4 w-full max-h-full">

<div className="p-2 bg-white rounded-lg shadow-lg z-10 flex flex-col h-full">
<div className="flex items-center">
<div>
  <svg width="6" height="30" viewBox="0 0 6 51" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="3" y1="51" x2="3" y2="-1.31134e-07" stroke="#FDB73F" stroke-opacity="0.71" stroke-width="6"/>
  </svg>
</div>
<div>
  <h3 className="headingtext ml-2 font-bold text-sm text-left" style={{lineHeight:'1rem'}}>Manual reporting <br></br> hassles</h3>
</div>
</div>
<p className="text-left text-xs faqtext mt-2">
Manual data collection is prone to errors and delays, reducing data accuracy and  operational efficiency.

</p>
</div>

</div>





    </div>
    </div>

      {/* Display only on laptop/desktop */}

<div className="hidden lg:block sm:hidden">
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-5xl mx-auto pblsection">
    {problems.map((problem, index) => (
      <div className={`hlinepblm lg:pr-7 lg:pl-1 ${
      index === problems.length - 1 ? 'border-r-0' : 'border-r'
    }`}
    key={index}>
        <div className="flex flex-col items-center space-y-4 w-full">
          <div className="p-4 bg-white rounded-lg shadow-lg z-10">
            <div className="flex items-center">
              <div>
                <svg width="6" height="40" viewBox="0 0 6 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line x1="3" y1="51" x2="3" y2="0" stroke="#FDB73F" stroke-opacity="0.71" stroke-width="6"/>
                </svg>
              </div>
              <div>
                <h3 className="headingtext ml-2 font-bold text-xl1 text-left" style={{lineHeight:'1.5rem'}}  dangerouslySetInnerHTML={{ __html: problem.title }}>
                
                </h3>
              </div>
            </div>
            <p className="text-left text-sm faqtext mt-4 mb-4"
            dangerouslySetInnerHTML={{ __html: problem.description }}></p>
           
          </div>
        </div>
      </div>
    ))}
  </div>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-5xl mx-auto pblsection">
   
      <div className="hlinepblm lg:pr-7 lg:pl-1">
        <div className="flex flex-col items-center space-y-4 w-full">
        <div className="flex col-auto items-center my-4">
          <div className="hf11">
          <svg width="300" height="1" viewBox="0 0 250 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="250" y2="0.5" stroke="#2B3C73" stroke-opacity="0.15"/>
            </svg>
          </div>
        </div>
        </div>
      </div>
      <div className="hlinepblm lg:pr-7 lg:pl-1">
        <div className="flex flex-col items-center space-y-4 w-full">
        <div className="flex col-auto items-center my-4">
          <div className="hf11">
          <svg width="300" height="1" viewBox="0 0 250 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="250" y2="0.5" stroke="#2B3C73" stroke-opacity="0.15"/>
            </svg>
          </div>
        </div>
        </div>
      </div>
      <div className="hlinepblm lg:pr-7 lg:pl-1 border-r-0">
        <div className="flex flex-col items-center space-y-4 w-full">
        <div className="flex col-auto items-center my-4">
          <div className="hf11">
          <svg width="300" height="1" viewBox="0 0 250 1" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="0.5" x2="250" y2="0.5" stroke="#2B3C73" stroke-opacity="0.15"/>
            </svg>
          </div>
        </div>
        </div>
      </div>

  </div>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-5xl mx-auto pblsection">
  {problems2.map((problem, i) => (
  <div
    className={`hlinepblm lg:pr-7 lg:pl-1 ${
      i === problems2.length - 1 ? 'border-r-0' : 'border-r'
    }`}
    key={i}
  >
        <div className="flex flex-col items-center space-y-4 w-full h-full">
          <div className="p-4 bg-white rounded-lg shadow-lg z-10 flex flex-col h-full">
            <div className="flex items-center">
         
              <div>
                <svg width="6" height="40" viewBox="0 0 6 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <line x1="3" y1="51" x2="3" y2="0" stroke="#FDB73F" stroke-opacity="0.71" stroke-width="6"/>
                </svg>
              </div>
              <div>
                <h3 className="headingtext ml-2 font-bold text-xl1 text-left" style={{lineHeight:'1.5rem'}}  dangerouslySetInnerHTML={{ __html: problem.title }}>
                
                </h3>
              </div>
            </div>
            <p className="text-left text-sm faqtext mt-4 mb-4"
            dangerouslySetInnerHTML={{ __html: problem.description }}></p>
           
          </div>
        </div>
      </div>
    ))}
  </div>
</div>
   

    <button className=" text-black font-semibold py-2 px-4 rounded  mb35 " style={{background: '#FDB740',paddingLeft: '2.5rem',
    paddingRight: '2.5rem',marginBottom:'0rem',marginTop:'3.5rem'
}} onClick={toggleModal}>
          Get a call back
        </button>
  </div>
  );
};

const ProblemCard = ({ title, description , clasnew }) => (
 
 
<div className="p-4 bg-white rounded-lg shadow-lg z-10">
  <div className="flex items-center">
    <div>
      <svg width="6" height="40" viewBox="0 0 6 51" fill="none" xmlns="http://www.w3.org/2000/svg">
        <line x1="3" y1="51" x2="3" y2="-1.31134e-07" stroke="#FDB73F" stroke-opacity="0.71" stroke-width="6"/>
      </svg>
    </div>
    <div>
      <h3 className="headingtext ml-2 font-bold text-xl1 text-left">{title}</h3>
    </div>
  </div>
  <p className="text-left text-sm faqtext mt-2">
    {description}
  </p>
</div>
);

export default ProblemsSection;