import React from 'react';
import image1 from '../assets/header/g13.webp';

import image2 from '../assets/header/g14.webp';

import image3 from '../assets/header/Layer4.webp';

import image4 from '../assets/header/g16.webp';

const UseCasesSection = ({toggleModal}) => {
  return (
    <div className="text-center py-10 bg-blue-50">
    <div className="container mx-auto max-w-custom-size sm:max-w-custom-size lg:max-w-4xl">
      <h2 className="text-2xl lg:text-3xl font-bold text-blue-900 mb-10 mt-10">
        Where can you use Dhaara Smart?
      </h2>
      <div className="grid gap-6 grid-cols-2 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-4 ">
        <UseCaseCard
          image={image1}
          title="Groundwater<br> metering"
        />
        <UseCaseCard
          image={image2}
          title="Water auditing <br>for businesses"
        />
        <UseCaseCard
          image={image3}
          title="Smart water <br>utilities"
        />
        <UseCaseCard
          image={image4}
          title="Precision<br> irrigation"
        />
      </div>
      <button className=" text-black font-semibold py-2 px-4 rounded  mb35 " style={{background: '#FDB740',paddingLeft: '2.5rem',
    paddingRight: '2.5rem',marginBottom:'1rem'
}} onClick={toggleModal}>
          Get a call back
        </button>
    </div>
  </div>
  );
};

const UseCaseCard = ({ image, title }) => (
  <div className="flex flex-col items-center">
  <img
    src={image}
    alt={title}
    className="w-24 h-24 sm:w-24 sm:h-24 lg:w-40 lg:h-40 object-cover rounded-lg mb-4"
  />
  <h3 className="text-sm lg:text-lg text-center font-normal text-greys-900" style={{lineHeight: '1.2rem',}} dangerouslySetInnerHTML={{ __html: title }}></h3>
</div>
);

export default UseCasesSection;